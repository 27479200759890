export const getSubscribersMixins = {
    methods: {
        getSubscriptions() {
            return this.$useJwt.subscription();
        },

        async getCurrentPlan() {
            const {data} = await this.getSubscriptions();
            return data.data.map(element => element.current_package.id);
        },

        async getDowngradePackages() {
            const {data} = await this.getSubscriptions();
            let getData = data.data.map(
                element => element.requested_downgrade_package
            );

            if (getData[0] == null) return null;
            else return getData[0];
        },

        async getUpgradePackages() {
            const {data} = await this.getSubscriptions();
            let getData = data.data.map(
                element => element.requested_upgrade_package
            );

            if (getData[0] == null) return null;
            else return getData[0];
        },

        async getPaymentPlan() {
            const {data} = await this.getSubscriptions();

            return data.data.map(
                element => element.payment_plan.payment_plan_name
            );
        },

        async getUuid() {
            const {data} = await this.getSubscriptions();
            return data.data[0].uuid;
        },
    },
};
