<template>
    <b-modal
        id="modal-prevent-crud-users"
        ok-variant="primary"
        ref="modal"
        :ok-title="$t(locale.send)"
        :cancel-title="$t(locale.cancel)"
        cancel-variant="outline-secondary"
        modal-class="modal-primary"
        centered
        :no-close-on-backdrop="true"
        @ok="validation"
        @close="closeModal"
        @cancel="closeModal"
        :title="$t(modalTitle)"
    >
        <b-card-text
            class="d-flex flex-column justify-content-center"
            style="gap: 1rem"
        >
            <div class="">
                <Loading v-if="loadingState" />
                <ValidationObserver v-show="showForm" ref="form">
                    <ValidationProvider
                        v-for="(form, index) in dataForm"
                        :key="index"
                        :rules="form.rules"
                        :name="$t(form.label)"
                        #default="{errors}"
                    >
                        <div class="d-flex mt-1 flex-column">
                            <label for="" class="h6 font-weight-bold">
                                {{ $t(form.label) }}
                                <span class="text-danger">*</span>
                            </label>

                            <b-form-input
                                v-if="form.type == 'input'"
                                v-model="form.value"
                                :placeholder="$t(form.label)"
                            />
                            <v-select
                                v-else
                                :options="roleOptions"
                                :clearable="false"
                                :placeholder="$t(form.label)"
                                v-model="form.value"
                                class="per-page-selector-send-method d-inline-block"
                            />

                            <span
                                class="text-danger"
                                style="margin-top: 3px"
                                v-text="errors[0]"
                            />
                        </div>
                    </ValidationProvider>
                </ValidationObserver>
            </div>
        </b-card-text>
    </b-modal>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import vSelect from 'vue-select';
import useAppConfig from '@core/app-config/useAppConfig';

export default {
    props: {
        modalStatus: {
            type: String,
        },
        uuid: {
            type: String,
        },
        modalTitle: {
            type: String,
        },
    },
    components: {
        vSelect,
        ValidationProvider,
        ValidationObserver,
    },

    data() {
        return {
            config: useAppConfig(),
            loadingState: false,
            showForm: true,
            locale: {
                send: 'UsersCard.send',
                cancel: 'UsersCard.cancel',
                title: 'UsersCard.modal.inviteTitle',
            },
            dataForm: [
                {
                    label: 'UsersCard.modal.name',
                    value: '',
                    rules: 'required',
                    type: 'input',
                },
                {
                    label: 'UsersCard.modal.email',
                    value: '',
                    rules: 'required|email',
                    type: 'input',
                },
                {
                    label: 'UsersCard.modal.role',
                    value: '',
                    rules: 'required',
                    type: 'select',
                },
            ],
            roleOptions: [],
        };
    },

    computed: {
        message() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'swe_message' : 'eng_message';
        },
        getLang() {
            return this.config.lang.value;
        },
    },

    methods: {
        closeModal() {
            this.$emit('closeModal');
            this.refreshForm();
        },

        getUsers(uuid) {
            this.loadingState = true;
            this.showForm = false;
            this.$useJwt
                .getUsersByid(uuid)
                .then(res => {
                    const {data} = res.data;

                    this.dataForm = [
                        {
                            label: 'UsersCard.modal.name',
                            value: data.full_name,
                            rules: 'required',
                            type: 'input',
                        },
                        {
                            label: 'UsersCard.modal.email',
                            value: data.email,
                            rules: 'required|email',
                            type: 'input',
                        },
                        {
                            label: 'UsersCard.modal.role',
                            value: data.role,
                            rules: 'required',
                            type: 'select',
                        },
                    ];
                })
                .finally(() => {
                    this.loadingState = false;
                    this.showForm = true;
                });
        },

        refreshForm() {
            this.dataForm = [
                {
                    label: 'UsersCard.modal.name',
                    value: '',
                    rules: 'required',
                    type: 'input',
                },
                {
                    label: 'UsersCard.modal.email',
                    value: '',
                    rules: 'required|email',
                    type: 'input',
                },
                {
                    label: 'UsersCard.modal.role',
                    value: '',
                    rules: 'required',
                    type: 'select',
                },
            ];

            this.$refs.form.reset();
        },

        editUsers() {
            this.$useJwt
                .updateUser(this.uuid, {
                    full_name: this.dataForm[0].value,
                    email: this.dataForm[1].value,
                    role: this.dataForm[2].value.value,
                })
                .then(res => {
                    this.$nextTick(() => {
                        this.$refs['modal'].toggle('#toggle-btn');
                    });
                    this.popupMsg(
                        this.$t('Message.Success'),
                        res.data[this.message],
                        'CheckIcon',
                        'success'
                    );
                    this.refreshForm();
                    this.$emit('refresh');
                })
                .catch(error => {
                    if (error.response) {
                        const {data} = error.response;
                        this.errorsMessage(data);
                    }
                });
        },

        errorsMessage(msg) {
            this.$refs.form.setErrors({
                [this.$t('UsersCard.modal.email')]: [msg[this.message]],
            });

            this.popupMsg(
                this.$t('Message.Failed'),
                msg[this.message],
                'AlertCircleIcon',
                'danger'
            );
        },

        inviteUsers() {
            this.$useJwt
                .inviteUsers({
                    name: this.dataForm[0].value,
                    email: this.dataForm[1].value,
                    role: this.dataForm[2].value.value,
                })
                .then(res => {
                    this.$nextTick(() => {
                        this.$refs['modal'].toggle('#toggle-btn');
                    });
                    this.popupMsg(
                        this.$t('Message.Success'),
                        res.data[this.message],
                        'CheckIcon',
                        'success'
                    );

                    this.refreshForm();

                    this.$emit('refresh');
                })
                .catch(error => {
                    if (error.response) {
                        const {data} = error.response;

                        this.errorsMessage(data);
                    }
                });
        },

        validation(event) {
            event.preventDefault();

            this.$refs.form.validate().then(res => {
                if (res) {
                    this.modalStatus == 'edit'
                        ? this.editUsers()
                        : this.inviteUsers();
                }
            });
        },
    },

    mounted() {
        this.$useJwt.getMyAccount().then(res => {
            const {role} = res.data.data;

            if (role == 'Owner') {
                this.roleOptions = this.$t('UsersCard.ownerRoles');
            } else this.roleOptions = this.$t('UsersCard.roles');
        });

        if (this.uuid) this.getUsers(this.uuid);
    },
};
</script>

<style></style>
