<template>
    <div>
        <b-card class="rounded-lg">
            <b-card-text>
                <b-card no-body>
                    <div class="m-2">
                        <b-row>
                            <b-col
                                cols="12"
                                md="6"
                                class="d-flex align-items-center justify-content-start mb-1 px-0 mb-md-0"
                            >
                                <i18n
                                    :path="locale.subtitle"
                                    tag="p"
                                    :for="locale.anchor"
                                >
                                    <template #userNumber>
                                        {{ numberOfUsers }}
                                    </template>
                                    <template #link>
                                        <router-link
                                            :to="{name: 'subscription'}"
                                            v-text="$t(locale.anchor)"
                                        />
                                    </template>
                                </i18n>
                            </b-col>

                            <b-col cols="12" class="px-0">
                                <div class="shadow leftCardBottomBorder">
                                    <div
                                        class="w-100 position-relative bg-secondaryColor p-2 text-white d-flex justify-content-between leftCardTopBorder"
                                    >
                                        <p
                                            class="mb-0 h2 font-weight-bold"
                                            style="color: inherit"
                                            v-text="$t(locale.currentUser)"
                                        />

                                        <b-button
                                            variant="white"
                                            v-b-modal.modal-prevent-crud-users
                                            @click="inviteUsers"
                                            class="text-secondaryColor position-relative"
                                            style="z-index: 1"
                                            v-text="$t(locale.inviteBtn)"
                                        />

                                        <img
                                            src="@/assets/duplioAsset/icons/usersIcon.png"
                                            width="200px"
                                            class="position-absolute"
                                            style="right: 0; bottom: -13px"
                                        />
                                    </div>
                                </div>

                                <Loading v-if="loadingState" />
                                <b-table
                                    v-else
                                    ref="table"
                                    responsive
                                    :items="usersItem"
                                    :fields="tableColumns"
                                    primary-key="id"
                                    show-empty
                                    :sort-desc.sync="sortDesc"
                                    :sort-by.sync="sortBy"
                                    :empty-text="$t(locale.tableEmpty)"
                                    class="position-relative"
                                >
                                    <template
                                        #head(checkbox)
                                        style="width: 20px"
                                    >
                                        <div
                                            class="d-flex"
                                            style="margin-top: 1px"
                                        >
                                            <b-form-checkbox
                                                v-model="selected"
                                                value="A"
                                                class="custom-control-primary my-auto"
                                            >
                                            </b-form-checkbox>
                                        </div>
                                    </template>
                                    <template #head(name)="data">
                                        <div class="d-flex">
                                            <span
                                                class="my-auto"
                                                style="margin-right: 5px"
                                                v-text="$t(data.field.label)"
                                            />

                                            <span
                                                class="d-flex flex-column position-relative"
                                            >
                                                <feather-icon
                                                    size="10"
                                                    @click="
                                                        sortingChanged(
                                                            data,
                                                            true
                                                        )
                                                    "
                                                    class="cursor-pointer"
                                                    icon="ChevronUpIcon"
                                                    style="pointer: cursor"
                                                    role="button"
                                                />

                                                <feather-icon
                                                    @click="
                                                        sortingChanged(
                                                            data,
                                                            false
                                                        )
                                                    "
                                                    size="10"
                                                    class="cursor-pointer"
                                                    icon="ChevronDownIcon"
                                                    role="button"
                                                />
                                            </span>
                                        </div>
                                    </template>
                                    <template #head(email)="data">
                                        <div class="d-flex">
                                            <span
                                                class="my-auto"
                                                style="margin-right: 5px"
                                                v-text="$t(data.field.label)"
                                            />

                                            <span
                                                class="d-flex flex-column position-relative"
                                            >
                                                <feather-icon
                                                    size="10"
                                                    @click="
                                                        sortingChanged(
                                                            data,
                                                            true
                                                        )
                                                    "
                                                    class="cursor-pointer"
                                                    icon="ChevronUpIcon"
                                                    style="pointer: cursor"
                                                    role="button"
                                                />

                                                <feather-icon
                                                    @click="
                                                        sortingChanged(
                                                            data,
                                                            false
                                                        )
                                                    "
                                                    size="10"
                                                    class="cursor-pointer"
                                                    icon="ChevronDownIcon"
                                                    role="button"
                                                />
                                            </span>
                                        </div>
                                    </template>
                                    <template #head(role)="data">
                                        <div class="d-flex">
                                            <span
                                                class="my-auto"
                                                style="margin-right: 5px"
                                                v-text="$t(data.field.label)"
                                            />

                                            <span
                                                class="d-flex flex-column position-relative"
                                            >
                                                <feather-icon
                                                    size="10"
                                                    @click="
                                                        sortingChanged(
                                                            data,
                                                            true
                                                        )
                                                    "
                                                    class="cursor-pointer"
                                                    icon="ChevronUpIcon"
                                                    style="pointer: cursor"
                                                    role="button"
                                                />

                                                <feather-icon
                                                    @click="
                                                        sortingChanged(
                                                            data,
                                                            false
                                                        )
                                                    "
                                                    size="10"
                                                    class="cursor-pointer"
                                                    icon="ChevronDownIcon"
                                                    role="button"
                                                />
                                            </span>
                                        </div>
                                    </template>
                                    <template #head(access)="data">
                                        <div class="d-flex">
                                            <span
                                                class="my-auto"
                                                style="margin-right: 5px"
                                                v-text="$t(data.field.label)"
                                            />

                                            <span
                                                class="d-flex flex-column position-relative"
                                            >
                                                <feather-icon
                                                    size="10"
                                                    @click="
                                                        sortingChanged(
                                                            data,
                                                            true
                                                        )
                                                    "
                                                    class="cursor-pointer"
                                                    icon="ChevronUpIcon"
                                                    style="pointer: cursor"
                                                    role="button"
                                                />

                                                <feather-icon
                                                    @click="
                                                        sortingChanged(
                                                            data,
                                                            false
                                                        )
                                                    "
                                                    size="10"
                                                    class="cursor-pointer"
                                                    icon="ChevronDownIcon"
                                                    role="button"
                                                />
                                            </span>
                                        </div>
                                    </template>
                                    <template #head(actions)="data">
                                        <div class="d-flex">
                                            <span
                                                class="my-auto"
                                                style="margin-right: 5px"
                                                v-text="$t(data.field.label)"
                                            />

                                            <span
                                                class="d-flex flex-column position-relative"
                                            >
                                                <feather-icon
                                                    size="10"
                                                    @click="
                                                        sortingChanged(
                                                            data,
                                                            true
                                                        )
                                                    "
                                                    class="cursor-pointer"
                                                    icon="ChevronUpIcon"
                                                    style="pointer: cursor"
                                                    role="button"
                                                />

                                                <feather-icon
                                                    @click="
                                                        sortingChanged(
                                                            data,
                                                            false
                                                        )
                                                    "
                                                    size="10"
                                                    class="cursor-pointer"
                                                    icon="ChevronDownIcon"
                                                    role="button"
                                                />
                                            </span>
                                        </div>
                                    </template>

                                    <!-- Column: date -->
                                    <template #cell(checkbox)="data">
                                        <b-form-checkbox v-model="data.value">
                                        </b-form-checkbox>
                                    </template>

                                    <template #cell(name)="data">
                                        <div class="d-flex">
                                            <b-media vertical-align="center">
                                                <template #aside>
                                                    <b-avatar
                                                        size="32"
                                                        :src="
                                                            data.item
                                                                .profile_picture
                                                        "
                                                    />
                                                </template>
                                            </b-media>

                                            <p class="font-weight-bold mb-0">
                                                {{ data.value }}
                                            </p>
                                        </div>
                                    </template>

                                    <template #cell(actions)="data">
                                        <div class="d-flex">
                                            <b-button
                                                v-b-modal.modal-prevent-crud-users
                                                @click="
                                                    editUser(data.item.uuid)
                                                "
                                                variant="flat-success"
                                                class="btn-icon rounded-circle"
                                            >
                                                <feather-icon
                                                    icon="Edit3Icon"
                                                />
                                            </b-button>

                                            <b-button
                                                v-b-modal.modal-prevent-remove-users
                                                @click="
                                                    removeUser(data.item.uuid)
                                                "
                                                variant="flat-danger"
                                                class="btn-icon rounded-circle"
                                            >
                                                <feather-icon
                                                    icon="Trash2Icon"
                                                />
                                            </b-button>
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </div>

                    <!-- Table -->

                    <div class="mx-2 mb-2">
                        <b-row align-h="end">
                            <!-- Pagination -->
                            <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                            >
                                <b-pagination
                                    v-model="pagination.currentPage"
                                    :total-rows="pagination.totalUsers"
                                    :per-page="pagination.perPage"
                                    first-number
                                    last-number
                                    class="mb-0 mt-1 mt-sm-0"
                                    prev-class="prev-item"
                                    next-class="next-item"
                                >
                                    <template #prev-text>
                                        <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                        />
                                    </template>
                                    <template #next-text>
                                        <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                        />
                                    </template>
                                </b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </b-card>
            </b-card-text>
        </b-card>

        <UsersUtillsModal
            v-if="openModal"
            @refresh="getUsers"
            @closeModal="closeModal"
            :modalStatus="modalStatus"
            :uuid="uuid"
            :modalTitle="locale.modalTitle"
        />

        <DeleteModal
            v-if="removeModal"
            :uuid="uuid"
            @refresh="getUsers"
            @closeModal="closeModal"
        />
    </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import UsersUtillsModal from '@/@core/components/user/UsersUtillsModal.vue';
import DeleteModal from '@/@core/components/user/DeleteModal.vue';
import {getUsersMixins} from '@/mixins/getUsersMixins';

export default {
    mixins: [getUsersMixins],
    components: {
        ValidationProvider,
        ValidationObserver,
        UsersUtillsModal,
        DeleteModal,
    },
    data() {
        return {
            selected: false,
            searchQuery: '',
            sortDesc: null,
            uuid: '',
            modalStatus: '',
            usersItem: [],
            editModal: false,
            loadingState: true,
            sortBy: '',
            selectMode: '',
            openModal: false,
            removeModal: false,
            tableColumns: [],
            pagination: {
                currentPage: 1,
                totalUsers: 0,
                perPage: 20,
            },
            locale: {
                title: 'UsersCard.title',
                subtitle: 'UsersCard.subtitle',
                anchor: 'UsersCard.anchor',
                removeTitle: 'UsersCard.modal.removeTitle',
                removeSubtitle: 'UsersCard.modal.removeSubtitle',
                currentUser: 'UsersCard.currentUser',
                inviteBtn: 'UsersCard.inviteBtn',
                yes: 'UsersCard.yes',
                cancel: 'UsersCard.cancel',
                modalTitle: '',
            },
        };
    },

    computed: {
        currentUsers() {
            return this.$store.getters['appConfig/currentUser'];
        },
    },

    watch: {
        searchQuery(value) {
            this.$useJwt.getUsers({params: {search: value}}).then(res => {
                const {results} = res.data;

                const {count} = res.data;

                this.pagination.totalUsers = count;

                this.renderErrorList(results);
            });
        },
        'pagination.currentPage': {
            handler(page) {
                this.$useJwt.getUsers({params: {page: page}}).then(res => {
                    const {results} = res.data;

                    const {count} = res.data;

                    this.pagination.totalUsers = count;

                    this.renderErrorList(results);
                });
            },
        },
    },

    methods: {
        typeColor(type) {
            switch (type) {
                case 'success':
                    return 'text-success';
                case 'info':
                    return 'text-secondaryColor';
                case 'error':
                    return 'text-danger';
            }
        },
        editUser(uuid) {
            this.openModal = true;
            this.uuid = uuid;
            this.modalStatus = 'edit';
            this.locale.modalTitle = 'UsersCard.modal.editTitle';
        },

        removeUser(uuid) {
            this.removeModal = true;
            this.uuid = uuid;
        },

        inviteUsers() {
            this.openModal = true;
            this.modalStatus = 'invite';
            this.locale.modalTitle = 'UsersCard.modal.inviteTitle';
        },
        onRowSelected(value) {
            this.openModal = true;
        },
        clearSelected() {
            this.$refs.table.clearSelected();
        },

        renderTableColoumn() {
            let tablePrototype = [
                {
                    key: 'checkbox',
                    tdClass: 'bTableThStyle',
                    thStyle: {width: '20px', padding: '0.72rem 10px'},
                },
                {
                    key: 'name',
                    label: 'UsersCard.modal.name',
                    tdClass: 'bTableTdDateStyle',
                    thStyle: {padding: '0.72rem 0'},
                },
                {
                    key: 'email',
                    label: 'UsersCard.modal.email',
                },
                {
                    key: 'role',
                    label: 'UsersCard.modal.role',
                },
                // {
                //     key: 'access',
                //     label: 'UsersCard.modal.access',
                // },
            ];

            if (this.currentUsers.role == 'Owner')
                tablePrototype.push({
                    key: 'actions',
                    label: 'UsersCard.modal.actions',
                });

            this.tableColumns = tablePrototype;
        },

        renderUsers(value) {
            let dataArray = [];
            this.loadingState = true;

            value.forEach(element => {
                dataArray.push({
                    uuid: element.uuid,
                    checkbox: false,
                    profile_picture: element.profile_picture,
                    name: element.full_name,
                    email: element.email,
                    role: element.role,
                    access: element.access,
                });
            });

            this.renderTableColoumn();

            this.usersItem = dataArray;

            this.loadingState = false;
        },
        getUsers() {
            this.$useJwt.getUsers().then(res => {
                const {data} = res.data;
                const {count} = res.data;

                this.pagination.totalUsers = count;

                this.renderUsers(data);
            });
        },

        sortingChanged(sortBy, desc) {
            this.sortBy = sortBy.column;

            this.sortDesc = desc;
        },

        closeModal() {
            this.openModal = false;
            this.removeModal = false;
            this.uuid = '';
        },
    },

    mounted() {
        this.getUsers();
    },
};
</script>

<style scoped lang="scss">
.per-page-selector {
    width: 90px;
}

.leftCardTopBorder {
    border-top-left-radius: 0.6rem !important;
    border-top-right-radius: 0.6rem !important;
}

.leftCardBottomBorder {
    border-bottom-left-radius: 0.6rem !important;
    border-bottom-right-radius: 0.6rem !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
