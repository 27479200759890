<template>
    <div>
        <b-modal
            id="modal-prevent-remove-users"
            ok-variant="primary"
            :ok-title="$t(locale.yes)"
            :cancel-title="$t(locale.cancel)"
            cancel-variant="outline-secondary"
            modal-class="modal-primary"
            centered
            clickToClose
            @ok="removeUser"
            @close="closeModal"
            @cancel="closeModal"
            @hide="closeModal"
            :title="$t(locale.removeTitle)"
        >
            <Loading v-if="loadingState" />
            <b-card-text
                v-if="alertMsg"
                class="d-flex flex-column text-center align-items-center justify-content-center"
                style="gap: 1rem"
            >
                <img
                    src="@/assets/duplioAsset/icons/warningCircle.png"
                    width="70px"
                    height="70px"
                />
                <p class="mb-0 pb-0">
                    {{
                        $t(locale.removeSubtitle, {
                            member,
                            company,
                        })
                    }}
                </p>
            </b-card-text>
        </b-modal>
    </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig';
export default {
    props: {
        uuid: {
            type: String,
        },
    },
    data() {
        return {
            config: useAppConfig(),
            member: '',
            company: '',
            loadingState: true,
            alertMsg: false,
            locale: {
                removeTitle: 'UsersCard.modal.removeTitle',
                removeSubtitle: 'UsersCard.modal.removeSubtitle',
                yes: 'UsersCard.yes',
                cancel: 'UsersCard.cancel',
            },
        };
    },

    computed: {
        message() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'swe_message' : 'eng_message';
        },
        getLang() {
            return this.config.lang.value;
        },
    },

    methods: {
        closeModal() {
            this.$emit('closeModal');
        },
        removeUser() {
            this.$useJwt.deleteUser(this.uuid).then(res => {
                this.popupMsg(
                    this.$t('Message.Success'),
                    res.data[this.message],
                    'CheckIcon',
                    'success'
                );
            });
            this.$emit('refresh');
            this.$emit('closeModal');
        },

        getCompany(companyUuuid) {
            this.$useJwt
                .getCompanyByid(companyUuuid)
                .then(res => {
                    const {company_name} = res.data;
                    this.company = company_name;
                })
                .finally(() => {
                    this.loadingState = false;
                    this.alertMsg = true;
                });
        },

        getUsers(uuid) {
            this.$useJwt.getUsersByid(uuid).then(res => {
                const {data} = res.data;
                this.member = data.full_name;
                this.getCompany(data.company);
            });
        },
    },

    mounted() {
        if (this.uuid) this.getUsers(this.uuid);
    },
};
</script>
